import { Card, CardBody, Text, Stack, Badge } from "@chakra-ui/react";
const Item = ({ name, registeredName, targetSegment, categories, gist, link }) => {
    return (
            <Card mt={1} mb={1}>
                <CardBody>
                    <Text fontSize='lg'><a href={link} target="_blank" rel="noopener noreferrer">{name}</a></Text>
                    <Text fontSize='sm'>{registeredName}</Text>
                    <Stack mt={3} direction='row'>
                        {targetSegment.map((segment, index) => (
                            <Badge colorScheme='purple' key={index}>{segment}</Badge>
                        ))}
                        <Badge>{categories}</Badge>
                    </Stack>
                    <Text mt={3}>
                        {gist}
                    </Text>
                </CardBody>
            </Card>
    );
};

export default Item;