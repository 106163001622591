import { ChakraProvider, Container, Text, Heading } from '@chakra-ui/react'

import Listing from "./pages/listing"

function App() {

  return (
    <ChakraProvider>
      <Container maxW='container.lg' mb={"10px"} >
        <Heading mb={3}>Finapps</Heading>
        <Text fontSize='l'>
          Listing Indian tech in finance
        </Text>
      </Container>
      <Listing />
    </ChakraProvider>
  )
}
export default App;
