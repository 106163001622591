const companies = [
    {
        name: "India Lends",
        registeredName: "GC Web Ventures Pvt. Ltd",
        targetSegment: ["D2C"],
        categories: "Distribution Partner",
        gist: "Distribution partner of PL, BL, CC and GL. Digital Gold Investment & Credit Score.",
        link: "https://indialends.com/"
    },
    {
        name: "PostPe",
        registeredName: "Resilient Innovations Private Limited",
        targetSegment: ["D2C"],
        categories: "BNPL",
        gist: "BNPL | 30d interest free credit.",
        link: "https://postpe.app/"
    },
    {
        name: "Credilio",
        registeredName: "Credilio Financial Technologies Pvt Ltd",
        targetSegment: ["D2C", "B2B"],
        categories: "Distribution Partner",
        gist: "DSA platform for CC and loans; CC distribution partner.",
        link: "https://www.credilio.in/"
    },
    {
        name: "Credit Vidya - CV Score",
        registeredName: "Infocredit Services Private Limited",
        targetSegment: ["B2B"],
        categories: "Credit Rating",
        gist: "Alternative credit rating for underwriting and risk management",
        link: "https://www.creditvidya.com/cv-score"
    },
    {
        name: "Credit Vidya - Income X",
        registeredName: "Infocredit Services Private Limited",
        targetSegment: ["B2B"],
        categories: "Income Assessment",
        gist: "Income assessment in 60s",
        link: "https://www.creditvidya.com/income-x"
    },
    {
        name: "Credit Vidya - Eve",
        registeredName: "Infocredit Services Private Limited",
        targetSegment: ["B2B"],
        categories: "Employment Verification",
        gist: "Employment verification in 60s",
        link: "https://www.creditvidya.com/eve"
    },
    {
        name: "Rupeek",
        registeredName: "Rupeek Fintech Private Limited",
        targetSegment: ["D2C"],
        categories: "Credit Lending",
        gist: "Cheap gold loans",
        link: "https://rupeek.com/"
    },
    {
        name: "Propelld",
        registeredName: "Bluebear Technology Private Limited",
        targetSegment: ["D2C"],
        categories: "Credit Lending",
        gist: "Education Loans",
        link: "https://propelld.com/"
    },
    {
        name: "Niro",
        registeredName: "QFI Technologies Private Limited",
        targetSegment: ["D2C"],
        categories: "Credit Lending",
        gist: "Personal Loans, Only pre approved loans",
        link: "https://niro.money/"
    },
    
    

]

export default companies;