import { ButtonGroup, Button } from "@chakra-ui/react";


const Pagination = ({ nextPage, prevPage }) => {
    return (
        <ButtonGroup variant='outline' spacing='6'>
            <Button onClick={prevPage}> Prev</Button>
            <Button onClick={nextPage}>Next</Button>
        </ButtonGroup>
    );
};
export default Pagination;