import { VStack, Container } from "@chakra-ui/react";
import Item from "./item";
import React, { useState } from 'react';
import companies from './../resources/data.js';
import Pagination from "./pagination.jsx";

function Listing() {
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage] = useState(10);



    const start = (currentPage - 1) * perPage
    const end = currentPage * perPage
    const currentPageItems = companies.slice(start, end);

    const nextPage = () => {
        if (!(currentPageItems.length < perPage)) {
            setCurrentPage((currentPage) => currentPage + 1);
        }
        
    }

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((currentPage) => currentPage - 1);
        }
    }

    return (
        <VStack>
            <Container maxW='container.lg'  >
                {currentPageItems.map((company, index) => (
                    <Item key={index}
                        name={company.name}
                        registeredName={company.registeredName}
                        targetSegment={company.targetSegment}
                        categories={company.categories}
                        gist={company.gist}
                        link={company.link}
                    />
                ))}
            </Container>
            <Pagination
                nextPage={nextPage}
                prevPage={prevPage} />
        </VStack>
    )

}
export default Listing;
